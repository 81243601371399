// This object is where most of the information on my portfolio will be stored. This will be passed into App.js for use throughout the website

const DATA = {
    heroSection: {
        title: "Jamie Hannah",
        subtitle: "Front End Developer"
    },
    technologies: {
        html: {
            logo: "html logo",
            logoHover: "html logo on hover"
        }
    }
}

export default DATA;