import './App.css';
import data from './data/data';

function App() {
  return (
    <div className="App">
      <header className="App-hero-section full-height flex-center">
          <h1 className="name-title">{data.heroSection.title}</h1>
          <h3 className="job-title">{data.heroSection.subtitle}</h3>
          <div className="box"></div>
      </header>
      <section className="contact-section full-height flex-center">
        <p>My portfolio is currently under construction. Check back soon or get in touch.</p>
        <a className="link-text" href="mailto:jamiehannahdev@gmail.com">Email me</a>
        <a className="link-text" href="tel:+447725727185">Call me</a>
      </section>
    </div>
  );
}

export default App;
